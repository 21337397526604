<template>
  <main class="page-projects page-show-project p-0 d-flex flex-column">
    <div class="nav-container">
      <div class="container">
        <ul class="nav nav-pills nav-fill">
          <li class="nav-item">
            <router-link class="nav-link active" :to="{ name: 'projects.show' }" aria-current="page">Dati</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'projects.edit' }">Modifica</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'projects.visea' }">Modulo ViSEA</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'projects.visea.graph' }">Modulo ViSEA (Grafo)</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'projects.tutorial' }">Tutorial</router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="flex-grow-1 container py-5">
      <div class="table-responsive mb-5">
        <table class="table table-condensed visea-info-table">
          <tbody>
          <tr>
            <td class="title">Nome</td>
            <td>{{ project.name }}</td>
          </tr>
          <tr>
            <td class="title">Modulo visea</td>
            <td>{{ project.hasModuleVisea ? 'Attivo' : 'Non attivo' }}</td>
          </tr>
          <tr>
            <td class="title">Note</td>
            <td>{{ project.notes }}</td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="d-flex flex-wrap justify-content-between align-items-center mb-3">
        <h2 class="mb-0">Livelli<template v-if="projectModules.length > 0"> ({{ totalResults }})</template></h2>
        <router-link class="btn btn-primary font-weight-bold text-white" :to="{ name: 'modules.new' }">Aggiungi livello</router-link>
      </div>

      <template v-if="projectModules && projectModules.length > 0">
        <AwlTable :heading="tableHeadings" :rows="tableRows" class="mb-3">
          <template #column-nb-0="{ rowIndex }">{{ rowIndex + 1 }}</template>

          <template #actions-content="{ row }">
            <ActionShow :to="{ name: 'modules.show', params: { moduleId: row.id } }" />
            <ActionEdit class="ml-2" :to="{ name: 'modules.edit', params: { moduleId: row.id } }" />
          </template>
        </AwlTable>
      </template>
      <Alert v-else status="primary">Nessun livello presente.</Alert>
    </div>
  </main>
</template>
<script>

import rolesMixin from '@/mixins/roles';
import pageableMixin from '@/libs/Pagination/mixins/pageable';
import $api from '@/libs/OAuth2/services/api';

export default {
  mixins: [rolesMixin, pageableMixin],
  components: {
    Alert: () => import('@/libs/Feedback/components/Alert'),
    AwlTable: () => import('@/libs/Table/components/AwlTable'),
    ActionShow: () => import('@/libs/Table/components/Actions/ActionShow'),
    ActionEdit: () => import('@/libs/Table/components/Actions/ActionEdit'),
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      projectModules: [],
    };
  },
  computed: {
    tableHeadings () {
      return [
        { key: 'position', label: '' },
        { key: 'name', label: 'Nome' },
      ];
    },
    tableRows () {
      return (this.projectModules || []);
    },
  },
  methods: {
    fetchResults (page = this.page) {
      return $api.listModules(this.project.id, 1, 50, { project: this.project.id })
        .then(res => {
          this.projectModules = res?.data?.projectModules || [];
          this.setMetadata(res?.data);
        })
        .catch(() => this.$log.error)
      ;
    },
  },
  mounted () {
    this.fetchResults();
  },
};

</script>
